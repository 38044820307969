<script>
    import {mapGetters} from "vuex";
    import VueRouter from 'vue-router'

    const {isNavigationFailure} = VueRouter;


    export default {
        name: "RouteDispatcher",
        computed: {
            ...mapGetters({'visitedViewTags': 'tags'})
        },
        methods: {
            goto(route, orElseTargetPath = route && route.path) {
                //路由跳转时，优先找到已打开的相同id的tab其最新的fullPath并跳转，以避免丢失旧tab的path上的参数
                const visitedViewTags = this.visitedViewTags;
                const routeName = route && route.name;
                let targetRoute, visitedSameNameTag;
                if (routeName
                    && visitedViewTags.length
                    && (visitedSameNameTag = visitedViewTags.find(({name}) => name === routeName))) {
                    targetRoute = {path: visitedSameNameTag.path};
                } else {
                    targetRoute = {path: orElseTargetPath};
                }
                const errorHandler = (error => {
                    if (isNavigationFailure(error)) {
                        return;
                    }
                    console.error(error);
                });
                this.$router.push(targetRoute).catch(errorHandler);
            }
        }
    }
</script>

<style scoped>

</style>
