<template>
    <div class="sidebar">
        <div class="side-header">{{$bjggs.APP_NAME}}</div>
        <div class="side-body">
            <el-scrollbar class="x-hide">
                <el-menu :unique-opened="true"
                         @select="handleMenuSelect"
                         :default-active="activeMenu"
                         background-color="#304156"
                         text-color="#bfcbd9"
                         active-text-color="#ffd04b">
                    <sub-menu v-for="route in permission_routes"
                              :route="route"
                              :key="route.path"
                              :fullPath="route.path"></sub-menu>
                </el-menu>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import RouterDispatcher from './components/RouteDispatcher'
    import {mapGetters} from "vuex";

    export default {
        name: 'SideBar',
        mixins: [RouterDispatcher],
        components: {
            SubMenu: () => import('./SubMenu')
        },
        computed: {
            ...mapGetters(["permission_routes"]),
            activeMenu() {
                const {meta, path} = this.$route;
                const activeMenu = meta && meta.activeMenu;
                return activeMenu || path;
            }
        },
        methods: {
            handleMenuSelect(index, indexPath, {route}) {
                //菜单点击时，优先找到已打开的相同name的tag其最新的path并跳转，以避免丢失旧tab的path上的参数
                this.goto(route, index);
            }
        }
    };
</script>
